<template>
   <!-- <center> -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="footer__content">
                            <div class="footer__social">
                                <img style="width: 100px;" src="static/img/zain_gray.png" alt="">
                            </div>
                            <span>
                                <small class="footer__copyright">Powered by Fenteo © 2024<br> Designed by BYTE Co.ltd </small> 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    <!-- </center> -->
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>

</style>