<template>
    <AppHeader />
    <Suspense>
      <template #default>
    <AppHome />
    </template>
      <template #fallback>
        <AppLoading />
      </template>
    </Suspense>
    <AppFooter />
</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/layouts/AppHeader'
// import AppHome from '@/components/Pages/AppHome'
import AppFooter from '@/components/layouts/AppFooter'
import AppLoading from "@/components/Spinners/AppLoading";
import { defineAsyncComponent, Suspense } from "vue";
const AppHome = defineAsyncComponent(() =>
  import('@/components/Pages/AppHome')
)
export default {
  name: 'Home',
  components: {
    AppHeader,
    AppHome,
    AppFooter,
    AppLoading,
    Suspense
  }
}
</script>
